<template>
  <div class="page-content has-padding">
    <p class="title">{{ $t('Views.Carts.CartDetail.Main_Title') }}</p>

    <div v-if="showNoCartFound">
      <error-animation />

      <div class="title">Cart not found</div>
    </div>

    <cart-detail v-if="cart" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import cartProvider from '@/providers/cart'

const CartDetail = () => import('@/components/Carts/CartDetail')
const ErrorAnimation = () => import('@/components/UI/Components/ErrorAnimation')

export default {
  components: {
    'cart-detail': CartDetail,
    'error-animation': ErrorAnimation,
  },

  data() {
    return {
      cartId: Number(this.$route.params.cartId),
      showNoCartFound: false,
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),
  },

  created() {
    if (this.cart === null) {
      this.getCartById()
    }
  },

  methods: {
    ...mapMutations('cartStore', ['setCart']),

    getCartById() {
      let self = this
      self.showNoCartFound = false

      cartProvider.methods
        .getCartById(self.cartId)
        .then((response) => {
          if (response.status === 200) {
            self.setCart(response.data)
          }
        })
        .catch((error) => {
          //console.log(error.response)
          if (error.status === 404) {
            self.showNoCartFound = true
          }
        })
        .finally(() => {})
    },
  },
}
</script>
